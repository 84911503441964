import { i18n } from "~/plugins/translator";

export const getOwnerName = function (owner) {
    let cd = 0;

    if (owner.company) cd = 1;
    else if (owner.person) cd = 2;

    if (cd === 1 && owner && owner.company.name) {
        return owner.company.name;
    } else if (cd === 2 && owner && owner.person.name) {
        return getPersonName(owner.person, owner.personStatus);
    } else if (owner.name) {
        let s = owner.name;

        if (owner.personStatus && owner.personStatus === 'doed') {
            s += i18n.t("PERSON_DECEASED");
        }

        return s;
    } else {
        return i18n.t("VALUE_NOT_REGISTERED");
    }
};

export const getPersonName = function (person, personStatus) {
    if (!person.name) return i18n.t("PERSON_WITH_NAME_PROTECTION");

    let names = person.name.split(",");
    let s;

    if (names.length < 2) {
        s = names[0];
    } else {
        s = names[1] + " " + names[0];
    }

    if (personStatus && personStatus === 'doed') {
        s += i18n.t("PERSON_DECEASED");
    }

    return s;
};
