
import { getOwnerName } from "~/helpers/owner-helpers.js";

export default {
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  computed: {
    transactions: function () {
      return this.property.ownerChanges;
    },

    transactionsByYear: function () {
      let transactionsBucketed = {};

      this.transactions.forEach((transaction) => {
        let year = new Date(transaction.handoverDate).getFullYear();
        if (!transactionsBucketed[year]) {
          transactionsBucketed[year] = [];
        }
        transactionsBucketed[year].push(transaction);
      });

      for (const year in transactionsBucketed) {
        if (transactionsBucketed[year].length >= 2) {
          let filteredTransactions = [];
          transactionsBucketed[year].forEach((transaction) => {
            //We expect a transaction to have at least ownershipChanges and transactionInfo, to be able to present meaningful data.
            if (transaction.ownershipChanges && transaction.transactionInfo) {
              filteredTransactions.push(transaction);
            }
          });
          transactionsBucketed[year] = filteredTransactions;
        }
      }
      return transactionsBucketed;
    },
  },

  methods: {
    getOwnerName: getOwnerName,

    getAddresses(ownerChange) {
      const a = [];

      ownerChange.forEach((o) => {
        const property = o.property;
        const bfeNumber = property.bfeNumber;

        if (property && property.plots) {
          property.plots.forEach((plot) => {
            if (plot.address) {
              a.push({ bfeNumber, ...plot.address });
            }
          });
        } else if (property && property.condo) {
          if (property.condo.unit && property.condo.unit.address) {
            const address = property.condo.unit.address;
            a.push({ bfeNumber, ...address });
          }
        } else if (property && property.buildingOnForeignPlot) {
          property.buildingOnForeignPlot.buildings?.forEach((b) => {
            if (b.address) {
              a.push({ bfeNumber, ...b.address });
            }
          });
        }
      });

      return a;
    },
    getSellersChanges(transaction) {
      return transaction.ownershipChanges?.filter((ownershipChange) => ownershipChange.seller != null) ?? [];
    },
    getBuyersChanges(transaction) {
      return transaction.ownershipChanges?.filter((ownershipChange) => ownershipChange.buyer != null) ?? [];
    },

    calculateOwnershipShare(ownershipChange) {
      let share = 0;

      if (ownershipChange.buyer != null && ownershipChange.buyerShareDenominator != null && ownershipChange.buyerShareNumerator != null) {
        return ownershipChange.buyerShareNumerator / ownershipChange.buyerShareDenominator;
      } else if (ownershipChange.seller != null && ownershipChange.sellerShareDenominator != null && ownershipChange.sellerShareNumerator != null) {
        return ownershipChange.sellerShareNumerator / ownershipChange.sellerShareDenominator;
      }

      return share;
    },

    getTransferTypeTranslationValue(transferType) {
      switch (transferType) {
        case "Almindelig fri handel":
          return this.$t("COMPANY_TRANS_HANDOVER_FREE_MARKET");
        case "Anden overdragelse":
          return this.$t("COMPANY_TRANS_HANDOVER_OTHER");
        case "Interessesammenfald":
          return this.$t("COMPANY_TRANS_HANDOVER_COINCIDING");
        case "Mageskifte":
          return this.$t("COMPANY_TRANS_HANDOVER_SPOUSE");
        case "Almindelig fri handel særlige vilkår":
          return this.$t("COMPANY_TRANS_HANDOVER_SPECIAL");
        case "Familieoverdragelse":
          return this.$t("COMPANY_TRANS_HANDOVER_FAMILY");
        case "Ikke oplyst":
          return this.$t("COMPANY_TRANS_HANDOVER_UNKNOWN");
        default:
          this.$t("COMPANY_TRANS_HANDOVER_UNKNOWN");
      }
    },
    navigateToCompany(company) {
      const search = {
        id: company.company.cvr,
        searchTerm: company.name,
      };
      this.$store.dispatch("search/initiateCompanySearch", { search, track: "transaction card" });
    },
    navigateToPerson(person) {
      if (person.id) {
        let trackingRoute = "";
        switch (getPropertyType(this.property)) {
          case PROPERTY_TYPE_MAIN_PROPERTY:
            trackingRoute = "main property transactions";
            break;
          case PROPERTY_TYPE_CONDO:
            trackingRoute = "condo transactions";
            break;
          case PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT:
            trackingRoute = "bofp transactions";
        }
        trackPersonLookup(person.id, person.name, trackingRoute);
        this.$router.push({
          name: "explore-person-id-ownership",
          params: { id: person.id },
        });
      }
    },
    navigateToProperty(address) {
      const search = {
        id: address.bfeNumber,
        searchTerm: `${address.streetName} ${address.streetNumber} ${address.postalCode} ${address.city}`,
      };
      this.$store.dispatch("search/initiateAddressSearch", { search, track: "transaction card" });
    },
    getAddressAsString(address) {
      let addressString = `${address.streetName} ${address.streetNumber}`;

      if (address.floorNumber) {
        addressString = addressString.concat(`, ${address.floorNumber}.`);
      }
      if (address.doorNumber) {
        addressString = addressString.concat(` ${address.doorNumber}`);
      }

      addressString = addressString.concat(`, ${address.postalCode} ${address.city}`);
      return addressString;
    },
  },
};

import { trackPersonLookup } from "~/helpers/tracking-helpers/person-tracking-helpers.js";
import { getPropertyType, PROPERTY_TYPE_MAIN_PROPERTY, PROPERTY_TYPE_CONDO, PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT } from "~/helpers/property-helpers.js";
