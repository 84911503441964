
import PersonLink from "./components/PersonLink.vue";
import CompanyLink from "./components/CompanyLink.vue";

export default {
  components: {
    PersonLink,
    CompanyLink,
  },
  props: {
    owner: {
      type: Object,
      required: true,
    },

    trackingOrigin: {
      type: String,
      default: null
    },

    includeShares: {
      type: Boolean,
      default: false
    }
  },
};
