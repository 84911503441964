
import { group_digits_in_number, filter_valuations } from "~/plugins/common-fns.js";
import { filter } from "~/composables/useFilter";

export default {
  inheritAttrs: false,

  props: {
    valuations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      range: 10,
      datacollection: null,
    };
  },

  computed: {
    filter() {
      return filter;
    },

    sortedValuations() {
      if (this.valuations) {
        return filter_valuations(this.valuations);
      }
      return null;
    },

    slicedValuations() {
      if (this.sortedValuations) {
        return this.sortedValuations
          .slice(0, 5)
          .map((x) => {
            return x;
          })
          .reverse();
      }
      return null;
    },

    rows() {
      if (this.sortedValuations) {
        let dates = [];
        let propertyValuations = [];
        let groundValuations = [];
        let usages = [];
        let taxes = [];
        // let landTaxes = [];
        this.sortedValuations
          .slice(0, 5)
          .reverse()
          .forEach((x) => {
            dates.push(this.$moment(x.valuationDate).format("YYYY-MM-DD"));
            propertyValuations.push(x.propertyValuation);
            groundValuations.push(x.landValuation);
            usages.push(x.usageText);
            taxes.push(x.daekningsAfgift);
            // landTaxes.push(Math.round(x.grundskyld));
          });

        return {
          DATE: dates,
          C_PROPVALUE: propertyValuations,
          C_LANDVALUE: groundValuations,
          C_USAGE: usages,
          C_COMTAX: taxes,
          // C_LANDTAX: landTaxes,
        };
      }
      return null;
    },

    allYears() {
      if (this.sortedValuations) {
        return this.sortedValuations.map((x) => x.year);
      }
      return null;
    },

    maxYears() {
      if (this.allYears) {
        return this.allYears.length;
      }
      return 5;
    },
  },

  mounted() {
    this.fillData();
  },

  methods: {
    group_digits_in_number: group_digits_in_number,
    fillData() {
      if (this.sortedValuations) {
        this.datacollection = {
          xaxis: {
            categories: this.sortedValuations
              .map((x) => x.year)
              .slice(0, this.range + 1)
              .reverse(),
          },
          series: [
            {
              name: this.$t("C_LANDVALUE"),
              data: this.sortedValuations
                .map((x) => x.landValuation)
                .slice(0, this.range + 1)
                .reverse(),
            },

            {
              name: this.$t("C_PROPVALUE"),
              data: this.sortedValuations
                .map((x) => x.propertyValuation)
                .slice(0, this.range + 1)
                .reverse(),
            },
          ],
        };
      }
    },
  },
};
